<template>
  <v-container class="my-8">

    <h1 class="text-h4 mb-4">Ficha de dados do GRESP</h1>
    <p>
      Inscreva-se como membro do GRESP ou atualize a sua inscrição!<br/>
      <span class="text--disabled">Todos os campos são de preenchimento obrigatório.</span>
    </p>

    <v-stepper vertical v-model="stepper" class="elevation-0">

      <v-stepper-step class="text-bold" :complete="stepper > 1" step="1">
        Tipo de inscrição
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-row>
          <v-col>
            <v-radio-group v-model="form.is_new" class="ml-2">
              <v-radio label="Nova Inscrição" :value="true"/>
              <v-radio label="Atualização da inscrição" :value="false"/>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" @click="stepper = 2">
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step class="text-bold" :complete="stepper > 2" step="2">
        Informação do Clínico
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-row>
          <v-col class="col-12 col-md-6 pr-md-12">
            <v-text-field
              label="Nome"
              v-model="form.name"
            ></v-text-field>
            <v-text-field
              label="Email"
              v-model="form.email"
              :rules="[rules.email]"
              type="email"
            ></v-text-field>
            <v-text-field
              label="Nº de Sócio da APMGF"
              v-model="form.apmgf_num"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Local de trabalho"
              v-model="form.work_location"
            ></v-text-field>
            <v-select
              label="Zona de trabalho"
              v-model="form.work_area"
              :items="data.work_areas"
            ></v-select>
          </v-col>
          <v-col class="col-12 col-md-6">
            <div class="text-h6">
              Funções que desempenha
            </div>
            <v-checkbox
              class="ml-4 mt-1"
              v-model="form.roles"
              :label="role.description"
              :value="role.id"
              v-for="role in data.roles"
              :key="`role-${role.id}`"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" :disabled="!isStep2Valid" @click="stepper = 3">
              Continuar
            </v-btn>
            <v-btn text class="ml-2" @click="stepper--">
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-step class="text-bold" :complete="stepper > 3" step="3">
        Participação
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-row>
          <v-col class="col-12 col-md-6 pr-md-12">
            <div class="mb-4 text-h6">
              Participação presente
            </div>
            <v-row
              align="center"
              class="ml-4 py-1 my-0"
              v-for="(presPart, presIdx) in data.present_participations"
              :key="`present-${presPart.id}`"
            >
              <v-checkbox
                class="mr-2 mt-0"
                v-model="form.present_participations[presIdx].selected"
                :label="presPart.description"
                hide-details
                @change="$forceUpdate();checkPresentParticipationsFilled()"
              ></v-checkbox>
              <v-text-field
                class="textfield-justification"
                v-if="form.present_participations[presIdx].selected"
                v-model="form.present_participations[presIdx].description"
                placeholder="Qual?"
                @change="checkPresentParticipationsFilled()"
                hide-details
                multiple
                dense
              ></v-text-field>
            </v-row>
          </v-col>
          <v-col class="col-12 col-md-6">
            <div class="mb-4 text-h6">
              Interesse em futura participação
            </div>
            <v-row
              align="center"
              class="ml-4 py-1 my-0"
              v-for="futurPart in data.future_participations"
              :key="`present-${futurPart.id}`"
            >
              <v-checkbox
                class="shrink mr-2 mt-0"
                v-model="form.future_participations.selected"
                :label="futurPart.description"
                :value="futurPart.id"
                hide-details
              ></v-checkbox>
              <v-text-field
                v-if="futurPart.id == '1'"
                :disabled="!form.future_participations.selected.includes('1')"
                v-model="form.future_participations.other_description"
                hide-details
                dense
              ></v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-6 pr-md-12">
            <div class="mb-4 text-h6">
              Áreas de Interesse
            </div>
            <v-row
              align="center"
              class="ml-4 py-1 my-0"
              v-for="area in data.areas_of_interest"
              :key="`present-${area.id}`"
            >
              <v-checkbox
                class="shrink mr-2 mt-0"
                v-model="form.areas_of_interest.selected"
                :label="area.description"
                :value="area.id"
                hide-details
              ></v-checkbox>
              <v-text-field
                v-if="area.id == '1'"
                :disabled="!form.areas_of_interest.selected.includes('1')"
                v-model="form.areas_of_interest.other_description"
                hide-details
                dense
              ></v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 mt-md-4">
            <div class="mb-2 text-h6">
              Participação em Grupos de Interesse do GRESP
            </div>
            <div class="ml-4 mt-4">
              <v-row
                v-for="item in data.work_groups_participations"
                :key="`workGroup-${item.id}`"
                align-content="space-between"
                align="center"
                dense
              >
                <v-col>
                  <span class="text--secondary">{{ item.description }}</span>
                </v-col>
                <v-col xs="12" sm="5" md="4" lg="3">
                  <v-select
                    v-model="form.work_groups_participations[item.id]"
                    :items="workGroupsValues"
                    hide-details
                    dense
                    outlined
                    @change="checkWorkGroupsFilled"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-4">
            <v-btn
              color="success"
              :disabled="!isStep3Valid || isSending"
              @click="submit"
              :loading="isSending"
            >
              Submeter
            </v-btn>
            <v-btn text class="ml-2" @click="stepper--">
              Voltar
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

    </v-stepper>

  </v-container>
</template>

<script>
import axios from "axios";
import { cloneDeep, countBy } from "lodash";

function returnNumber(val) {
  return Number(val) || 0;
}

export default {
  name: 'Registration',
  data: () => ({
    isSending: false,
    hasError: false,
    stepper: 1,
    presentParticipationsFilled: false,
    workGroupsFilled: false,
    workGroupsValues: [
      { text: "Escolha uma opção..." , value: null, disabled: true },
      { text: "Já sou membro" , value: 'member' },
      { text: "Estou interessado" , value: 'interested' },
      { text: "Não estou interessado" , value: 'uninterested' },
    ],
    formDefaultValues: {
      is_new: false,
      name: null,
      email: null,
      apmgf_num: null,
      work_area: null,
      work_location: null,
      roles: [],
      areas_of_interest: {
        selected: [],
        other_description: null,
      },
      present_participations: [
        { id: null, selected: false, description: null },
      ],
      future_participations: {
        selected: [],
        other_description: null,
      },
      work_groups_participations: {},
    },
    form: {},
    data: {},
    rules: {
      required: value => !!value || 'Required.',
      // counter: value => value.length <= 20 || 'Max 20 characters',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email inválido.'
      },
    },
  }),
  computed: {
    isStep2Valid() {
      return this.form.name != null &&
        this.form.name.trim().length > 0 &&
        this.form.email != null &&
        this.form.email.trim().length > 0 &&
        this.form.apmgf_num != null &&
        this.form.apmgf_num > 0 &&
        this.form.work_location != null &&
        this.form.work_location.trim().length > 0 &&
        this.form.work_area != null &&
        this.form.roles.length > 0
    },
    isStep3Valid() {
      return this.form.areas_of_interest.selected.length > 0 &&
      this.form.future_participations.selected.length > 0 &&
      this.presentParticipationsFilled &&
      this.workGroupsFilled
    },
  },
  methods: {
    checkPresentParticipationsFilled() {
      let valid = true;
      let filledCounter = 0;
      this.form.present_participations.forEach(item => {
        if(item.selected) filledCounter++;
        if(item.selected && (item.description == null || item.description.trim() == '')) {
          valid = false;
        }
      });
      this.presentParticipationsFilled = valid && filledCounter > 0;
    },
    checkWorkGroupsFilled() {
      const workGroupsValues = Object.values(this.form.work_groups_participations);
      const hasWorkGroupsNull = workGroupsValues.findIndex(item => item === null) > 0;
      this.workGroupsFilled = !hasWorkGroupsNull;
    },
    submit() {
      this.isSending = true;
      let parsedWorkGroupsParticipations = [];
      for (const k in this.form.work_groups_participations) {
        parsedWorkGroupsParticipations.push({ id: Number(k), value: this.form.work_groups_participations[k]});
      }
      const formData = JSON.parse(JSON.stringify(this.form));

      formData.areas_of_interest.selected = formData.areas_of_interest.selected.map(returnNumber);
      formData.future_participations.selected = formData.future_participations.selected.map(returnNumber);
      formData.work_groups_participations = parsedWorkGroupsParticipations;

      const apiEndpoint = `${process.env.VUE_APP_API_URL}`;
      axios.post(apiEndpoint, { action: "addRegistration", data: formData })
      .then(resp => resp.data)
      .then(data => {
        if(data.success) {
          // console.info("%c SUCCESS! ", "background: green; color: white");
          this.stepper = 1;
          this.form = JSON.parse(JSON.stringify(this.formDefaultValues));
          this.$router.push({name:"RegistrationSuccess"});
        } else {
          this.hasError = true;
          console.error(data);
        }
      })
      .catch(err => {
        this.hasError = true;
        console.error(err);
      })
      .finally(() => {
        this.isSending = false;
      });
    },
  },
  beforeMount() {
    this.form = JSON.parse(JSON.stringify(this.formDefaultValues));
    const apiEndpoint = `${process.env.VUE_APP_API_URL}?action=getRegistrationData`;
    axios.get(apiEndpoint)
    .then(resp => resp.data)
    .then(data => {
      this.data = data;

      // parse present_participations
      let presPartTpl = cloneDeep(this.formDefaultValues.present_participations[0]);
      data.present_participations.forEach((item, idx) => {
        this.form.present_participations[idx] = cloneDeep(presPartTpl);
        this.form.present_participations[idx].id = item.id;
      });

      // parse work groups participation
      data.work_groups_participations.forEach(item => {
        this.form.work_groups_participations[item.id] = null;
      });

      // parse work areas
      data.work_areas = data.work_areas.map(item => {
        return {
          text: item.description,
          value: item.id,
        };
      });
      data.work_areas.unshift({
        text: "Escolha uma opção...",
        value: null,
        disabled: true,
      });
    })
    .catch(err => {
      console.error(err);
    });
  },
};
</script>

<style lang="scss" scoped>
.textfield-justification {
  width: 100%;
  margin-left: 2em;  
}
</style>